import React, { Component } from 'react';


class Quotes extends Component
{
    render(){
        return (
        <div className="w3-content w3-container w3-row-padding w3-center w3-padding-64">
            <h3 className="w3-center">Det kan være at coaching kan hjelpe deg til å finne ut av ting.
Her er noen uttalelser etter samtaler jeg har hatt:
            </h3>

            <div className="quote-wrapper">
                <blockquote className="" >
                    <p className="quote-left">"Nå klarer jeg å komme tidsnok på jobbben."</p>
                </blockquote>
            </div>
            <div className="quote-wrapper">
                <blockquote className="" >
                    <p className="quote-right">"Nå klarer jeg å handtere negativt press."</p>
                </blockquote>
            </div>                   
            <div className="quote-wrapper">
                <blockquote className="" >
                    <p className="quote-left">"Nå kan jeg snakke bedre med min leder."</p>
                </blockquote>
            </div>
            <div className="quote-wrapper">
                <blockquote className="" >
                    <p className="quote-right">"Nå har jeg sluttet å være redd for å gi tilbakemeldinger."</p>
                </blockquote>
                <br/>
            </div>

        </div>
    )
    }
}

export default Quotes;