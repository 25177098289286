import React from 'react';
import Home from '../pages/home';

//import Nav from '../pages/nav';
import Portofolio from '../pages/portofolio';
import AboutMe from '../pages/om-meg';
import AboutYou from '../pages/om-deg';

import Contact from '../components/conact';
import Header from './header';
import Footer from './footer';
import Method from '../pages/method';
import Quotes from '../pages/quotes';
function App() {
  return (
    <div className="arb-container">
      <Header/>
      <Home/>
      <AboutYou/>
      <AboutMe/>
      <Method/>
      <Portofolio/>
      <Quotes/>
      <Contact/>
      <Footer/>

    </div> 
  );
}
export default App;