import React, {Component } from 'react';


class Footer extends Component
{
    render(){
        return (    
        <footer className="w3-center footer-image w3-padding-64 w3-opacity w3-hover-opacity-off">
          <a href="#home" className="w3-button w3-light-grey"><i className="fa fa-arrow-up w3-margin-right"></i>Til toppen</a>
        </footer>
        );
    }
}

export default Footer;