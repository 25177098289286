import React, {Component} from 'react';

class Home extends Component {
    render() {
	    return (
        <div className="bgimg-1 w3-display-container w3-opacity-min" id="home">
            <div className="w3-display-middle heading" style={{whiteSpace:"nowrap"}}>
              <span className="w3-center w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity">ARBEIDSMILJØCOACH</span>
            </div>
            <div className="w3-center w3-display-middle heading2">
              <span className="w3-center w3-padding-large simpletext w3-medium w3-animate-opacity">Har du tid til å stoppe opp litt?</span>

            </div><br/>
            <div className="arrow-container">
                <a data-scroll href="#about-you">
                  <div className="arrow"></div>
                </a>
            </div>
        </div>
	     );
    }
}
export default Home;