import React, {Component} from 'react';

class AboutMe extends Component
{
    render()
    {
        return(
        <div className="w3-content w3-container w3-padding-64" id="about">
        <h3 className="w3-center">Hvem er jeg?</h3>
        <p className="w3-center about">Mitt navn er Jane Løken. Jeg er 64 år og bor i Oslo. Jeg har erfart ulike arbeidsmiljø, kjent på gleder og sorger som arbeidslivet gir, på trygghet og usikkerhet, på å få energi og miste energi og mye mer. Jeg er en person som har ærlighet og nysgjerrighet som drivkraft. </p>
        <div className="w3-row">
            <div className="w3-col m6 w3-center w3-padding-large">
            <p><b><i className="fa fa-user w3-margin-right"></i>Jane Løken</b></p><br/>
            <img src="/img/jane2.jpg" className="w3-round w3-image w3-hover-opacity-off" alt="Of Me" width="500" height="333"/>
            </div>

            <div className="w3-col m6 w3-hide-small w3-padding-large">
            <br/><p className="w3-center about-text"><em>Faglig Bakgrunn</em></p>
            <p className="about"> Jeg er utdannet sosiolog fra Universitetet i Oslo, med spesialisering innen organisasjonssosiologi. Jeg har gjennomført ulike arbeidsmiljøkurs i løpet av min arbeidskarriere og har også et kurs i relasjonsledelse ved Norsk Gestalt Institutt.  Jeg går nå på siste året på gestaltcoaching ved samme institutt hvor praksis er en del av studiet. </p>
            <br/><br/><p className="w3-center about-text"><em>Arbeidserfaring</em></p>
            <p className="about">Jeg har lang og bred erfaring innen rådgivning og prosjektledelse, blant annen rådgivning innen Helse, Miljø og Sikkerhet (HMS) – der samtaler med ledere, medarbeidere og vernetjenesten har vært sentralt. </p>
            </div>
        </div>
        {/*<p className="w3-large w3-center w3-padding-16">Im really good at:</p>
        <p className="w3-wide"><i className="fa fa-camera"></i>Photography</p>
        <div className="w3-light-grey">
            <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{width:"90%"}}>90%</div>
        </div>
        <p className="w3-wide"><i className="fa fa-laptop"></i>Web Design</p>
        <div className="w3-light-grey">
            <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{width:"85%"}}>85%</div>
        </div>
        <p className="w3-wide"><i className="fa fa-photo"></i>Photoshop</p>
        <div className="w3-light-grey">
            <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{width:"75%"}}>75%</div>
        </div>*/}
        </div>
        )
    }
}

export default AboutMe;