import React, { Component } from "react";
import GroupIcon from '@material-ui/icons/Group';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

class Method extends Component
{
    render()
    {
        return(
        <div className="w3-content w3-container w3-padding-64" id="how">
        <h3 className="w3-center">Arbeidsmetode</h3>
        <p className="w3-center about">
        Mitt utgangspunkt som coach er å møte deg der du er, jeg har ikke på forhand definert utfall av
samtalene og jeg kan ikke bestemme over deg. Men jeg tror at du er motivert til å lære. Kjernen i
samtalene er å finne frem til tema du vil jobbe med, erfare og reflektere over erfaringene.
            <br/><em>En samtale i tre faser:</em>
            <br/>
        </p><br/>
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#f8ffbe52', color: 'black' }}
                contentArrowStyle={{ borderRight: '7px solid  #f8ffbe52' }}
                iconStyle={{ background: '#f8ffbe52', color: 'black' }}
                icon={<GroupIcon/>}
            >
                <h3 className="vertical-timeline-element-title">1. Oppstartsfase</h3>
                <li> Bli kjent - Hva skal vi gjøre sammen</li>
                <li> Sette opp en samarbeidsavtale hvis aktuelt</li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#f8ffbe52', color: 'black' }}
                contentArrowStyle={{ borderRight: '7px solid  #f8ffbe52' }}
                iconStyle={{ background: '#f8ffbe52', color: 'black' }}
                icon={<SearchIcon/>}
            >
              <h3 className="vertical-timeline-element-title">2. Arbeidsfase</h3>
                <li>Finne tema og tydeliggjøre disse</li>
                <li>Jobbe med temaene</li>
                <li>Oppdage handlingsalternativer</li>
                <li >Velge handlinger</li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#f8ffbe52', color: 'black' }}
                contentArrowStyle={{ borderRight: '7px solid  #f8ffbe52' }}
                iconStyle={{ background: '#f8ffbe52', color: 'black' }}
                icon={<DoneIcon/>}
            >
                <h3 className="vertical-timeline-element-title">3. Avslutningsfase</h3>
                <li>Refleksjon over erfaringer</li>
                <li>Hva tas med videre og hvordan?</li>
            </VerticalTimelineElement>
        </VerticalTimeline>
        <p className="w3-center about">
        Antall timer kan variere, men det er fint om vi kan sette opp et estimert antall.
        </p><br/>

            {/*
            <h3 className="w3-center">Arbeidsmetode</h3>
            <p className="w3-center">
                Mitt utgangspunkt som coach er å møte deg der du er, jeg har ikke på forhand definert utfall av samtalene og jeg kan ikke bestemme over deg. Men jeg forventer at du er motivert til å lære. Kjernen i samtalene er å finne frem til tema du vil jobbe med, erfare og reflektere over erfaringene. 
                <br/><em>En samtale i tre faser:</em>
                <br/>
            </p><br/>

            <div className="w3-row-padding w3-center">
                <div className="w3-third">
                    <i className="fa fa-group fa-5x circle-icon"/>

                    <p className="w3-center"> <b>1. Oppstartsfase</b></p>

                    <ul className="w3-center w3-ul">
                        <li>Bli kjent – hva skal vi gjøre sammen</li>
                        <li>Sette opp en samarbeidsavtale hvis aktuelt</li>
                    </ul>
                    <br/>
                </div>
                <div className="w3-third ">
                    <i className="fa fa-search fa-5x circle-icon"/>

                    <p className="w3-center"> <b>2. Arbeidsfase</b></p>
                    <ul className="w3-ul">
                        <li>Finne tema og tydeliggjøre disse</li>
                        <li>Jobbe med temaene</li>
                        <li>Oppdage handlingsalternativer</li>
                        <li >Velge handlinger</li>
                    </ul>
                    <br/>
                </div>

                <div className="w3-third">
                    <i className="fa fa-check fa-5x circle-icon"/>

                    <p className="w3-center"> <b>3. Avslutningsfase</b></p>
                    <ul className="w3-ul">
                        <li>Refleksjon over erfaringer</li>
                        <li>Hva tas med videre og hvordan?</li>
                    </ul>
                    <br/>
                </div>
            </div>
            */}
        </div>
        );
    }
}

export default Method;