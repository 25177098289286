import React, {Component} from 'react';


class Header extends Component
{

    constructor(props)
    {
        super(props);
        this.toggleFunction = this.toggleFunction.bind(this);
    }

    toggleFunction() {
        console.log("Toggle");
        let x = document.getElementById("navDemo");
        if (x.className.indexOf("w3-show") === -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }

    render()
    {
        return (

            <div className="w3-top">
                <div className="w3-bar" id="myNavbar">
                    <a className="w3-bar-item w3-button w3-hover-black w3-hide-medium w3-hide-large w3-right" onClick={this.toggleFunction} title="Toggle Navigation Menu">
                        <i className="fa fa-bars"></i>
                    </a>
                    <a href="#home" className="navitem w3-bar-item w3-button">Hjem</a>
                    <a href="#about-you" className="navitem w3-bar-item w3-button w3-hide-small"><i className="fa fa-address-card"></i> Hvem er du?</a>
                    <a href="#about" className="navitem w3-bar-item w3-button w3-hide-small"><i className="fa fa-user"></i> Hvem er jeg?</a>
                    <a href="#how" className="navitem w3-bar-item w3-button w3-hide-small"><i className="fa fa-balance-scale"></i> Metode </a>
                    <a href="#portfolio" className="navitem w3-bar-item w3-button w3-hide-small"><i className="fa fa-th"></i> Inspirasjon </a>
                    <a href="#contact" className="navitem w3-bar-item w3-button w3-hide-small"><i className="fa fa-envelope"></i> Kontakt</a>
                </div>

                <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium">
                    <a href="#about-you" className="w3-bar-item w3-button" onClick={this.toggleFunction}>Hvem er du?</a>
                    <a href="#about" className="w3-bar-item w3-button" onClick={this.toggleFunction}>Hvem er jeg?</a>
                    <a href="#how" className="w3-bar-item w3-button" onClick={this.toggleFunction}>Metode</a>
                    <a href="#portfolio" className="w3-bar-item w3-button" onClick={this.toggleFunction}>Inspirasjon</a>
                    <a href="#contact" className="w3-bar-item w3-button" onClick={this.toggleFunction}>Kontakt</a>
                </div>
            </div>

        )
    }
}


export default Header;