import React, {Component} from 'react';

class AboutYou extends Component
{
    render()
    {
        return(
        <div>
            <div className="w3-content w3-container w3-padding-64" id="about-you">
            <h3 className="w3-center">Hvem er du?</h3>

            <p className="w3-center about">Kanskje du har utfordringer i jobben, du vil finne ut av? </p>
            <p className="w3-center about">Ønsker du å justere på hvordan du har det som leder eller medarbeider? </p>
            <p className="w3-center about">Står du i endringer du er usikker på hvordan du skal takle? </p>
            <p className="w3-center about">Du har kanskje fått en ny rolle du ønsker å finne ut av? </p>
            <p className="w3-center about">Eller kanskje du ønsker å finne ut av hvordan du vil ta vanskelige samtaler?</p>
            <p className="w3-center about">Eller det kan være andre ting du har behov for?
            </p><br/>
            <p className="w3-center about">Uansett så kan det være en god ting å stoppe opp litt og finne ut av det.<br/> Jeg er en du kan reflektere sammen med.
            </p>
            </div>

            <div className="bgimg-4 w3-display-container w3-opacity-min">
            <div className="w3-display-middle">
            </div>
            </div>
        </div>
        )
    }
}

export default AboutYou;
