import React , {Component} from 'react';

class Contact extends Component{

    constructor(props)
    {
        super(props);
    }



    




    render(){
        return (
            <div>
            <div className="bgimg-3 w3-display-container w3-opacity-min">
            <div className="w3-display-middle">
                <span className="w3-xxlarge w3-text-white w3-wide">KONTAKT</span>
            </div>
            </div>

            <div className="w3-content w3-container w3-padding-64" id="contact">
            <h3 className="w3-center">Ta gjerne kontakt</h3>
            <p className="w3-center"><em></em></p>

            <div className="w3-row w3-padding-32 w3-section">
                <div className="w3-col m4 w3-container">
                <img src="/img/map.jpg" className="w3-image w3-round" style={{width:"100%"}} alt="!"/>
                </div>
                <div className="w3-col m8 w3-panel">
                <div className="w3-large w3-margin-bottom">
                    <i className="fa fa-map-marker fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Bergrådveien 28, Oslo, Norge<br/>
                    <i className="fa fa-phone fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Tlf: +47 91379543<br/>
                    <i className="fa fa-envelope fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Email: janeloeken@outlook.com<br/>
                </div>
                {/*<form onSubmit={this.handleSubmit} >
                    <div className="w3-row-padding" style={{margin:"0 -16px 8px -16px"}}>
                    <div className="w3-half">
                        <input className="w3-input w3-border" type="text" value={this.state.name} onChange={this.onNameChange} placeholder="Name" required name="Name"/>
                    </div>
                    <div className="w3-half">
                        <input className="w3-input w3-border" type="text" value={this.state.email} onChange={this.onEmailChange} placeholder="Email" required name="Email"/>
                    </div>
                    </div>
                    <input className="w3-input w3-border" type="text" value={this.state.message} onChange={this.onMessageChange} placeholder="Message" required name="Message"/>
                    <button className="w3-button w3-black w3-right w3-section" type="submit">
                    <i className="fa fa-paper-plane"></i> SEND MESSAGE
                    </button>
        </form>*/}
                </div>
            </div>
            </div>
            </div>

        )
    }
}


export default Contact;