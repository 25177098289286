import React , {Component} from 'react';
//import PortoItem from '../components/porto-item';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

class Portofolio extends Component{

    constructor(props)
    {
        super(props);
        this.state = {
            items: [
            <img src={"/img/cafe.jpg"} alt={"cafe"} />,
            <img src={"/img/forest-reflection.jpg" } alt={"reflection"} />,
            <img src={"/img/man_on_road.jpg" } alt={"person"} />,
            <img src={"/img/ice.jpg" } alt={"ice"} />,
            <img src={"/img/sun.png" } alt={"sun"} />,
            <img src={"/img/tunnel.jpg" } alt={"sparkle"} />,
            <img src={"/img/tree-reflection.jpg" } alt={"ice"} />,
          ]
        };
    }

    /*responsive = {
        0: { items: 1 },
        1024: { items: 2 },
      }*/


    render(){
        return(
        <div>
            <div className="bgimg-2 w3-display-container w3-opacity-min">
            </div>

            <div className="w3-content w3-container w3-padding-64" id="portfolio">
                <h3 className="w3-center">INSPIRASJON</h3>
                <p className="w3-center"><em>Her er noen bilder som kan være av relevanse. Det er ikke alltid lett å se ting med det blotte øyet.<br/> Det samme gjelder med arbeidsmiljøet.</em></p><br/>

                {/*<div className="w3-row-padding w3-center">
                    <PortoItem image="/img/forest-mist.jpg" width="100%" alt="The mist over the mountains"/>
                    <PortoItem image="/img/forest-reflection.jpg" width="100%"  alt="Coffee beans"/>
                    <PortoItem image="/img/sparkle-2.jpg" width="100%"  alt="Bear closeup"/>
                    <PortoItem image="/img/person-reflection.jpg" width="100%"  alt="Quiet ocean"/>
                </div>

                <div className="w3-row-padding w3-center w3-section">
                    <PortoItem image="/img/ice.jpg" width="100%" alt="The mist"/>
                    <PortoItem image="/img/cafe.jpg" width="100%" alt="My beloved typewriter"/>
                    <PortoItem image="/img/cranium.png" width="100%" alt="Empty ghost train"/>
                    <PortoItem image="/img/sun.png" width="100%" alt="Sailing"/>
                </div>*/}
                <AliceCarousel
                    items={this.state.items}
                    //responsive={this.responsive}
                    mouseTracking={true}
                    animationType="fadeout" 
                    animationDuration={800}
                    touchMoveDefaultEvents={false}
                />
                
            </div>
        </div>

        )
    }
}

export default Portofolio;